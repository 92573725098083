<template>
  <tr v-if="item" class="v-middle">
    <td>
      <button class="btn table-cart-btn" @click.prevent="addToCartOne">
        <span class="lnr lnr-cart"></span>
      </button>
    </td>
    <td nowrap>
      <a v-if="item && item.image" href="javascript:" @click.prevent="openModal">
        <img class="image-cover" :src="item.image" :alt="item.image_title" width="50" />
      </a>
    </td>
    <td nowrap>{{ item.code }}</td>
    <td nowrap>
      <a href="javascript:" @click.prevent="openModal" :title="item.title">{{
        truncate(item.title, 11)
      }}</a>
    </td>
    <td nowrap>{{ item.category_title }} / {{ item.sub_category_title }}</td>
    <td nowrap>
      <span class="woocommerce-Price-amount amount">
        <span class="woocommerce-Price-currencySymbol">$</span
        >{{ item.agreement_id ? item.amount_list[selectedAgreement] : item.range_amount }}
      </span>
    </td>
  </tr>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  props: ["item"],
  data() {
    return {
      modalOpen: false,
      qty: 1,
      selectedAgreement: 0,
    };
  },
  methods: {
    truncate(str, len) {
      if (str.length > len) {
        return str.substring(0, len) + "...";
      }
      return str;
    },
    openModal() {
      this.modalOpen = true;
      if (this.item && this.item.code) {
        $("#itemModal" + this.item.code).modal({ show: true });
      } else {
        alert("缺少商品資訊!");
      }
    },
    addToCartOne() {
      const url = "/api/add_to";
      const { item, maxItemQty, selectedAgreement } = this;
      if (maxItemQty > 0)
        axios
          .post(url, {
            item_id: item.key,
            qty: this.qty,
            amount: item.agreement_id ? item.amount_list[selectedAgreement] : item.amount,
            max_qty: maxItemQty, // 可購上限，無論是否有符合活動(campaign)，會帶到購物車，所以購物車數量檢查用max_qty即可
            agreement_id:
              item.agreement_id?.length > 0 ? item.agreement_id[selectedAgreement] : null,
            campaign_id: item.campaigns[0]?.id, //可用item.campaigns[0]因為在server端已經做好排序(多到少)
            price_list_id:
              item.price_list_id?.length > 0 ? item.price_list_id[selectedAgreement] : null,
            price_list_name:
              item.price_list_name?.length > 0 ? item.price_list_name[selectedAgreement] : null,
          })
          .then(({ data }) => {
            if (data.success) {
              // 取得購物車
              this.$store.dispatch("getCurrentCart");
            }
            Swal.fire({
              title: data.title,
              text: data.message,
              icon: data.success ? "success" : "error",
              confirmButtonText: "關閉",
            });
          });
      else {
        // 有可能是超過campagin量
        Swal.fire({
          title: "加入失敗",
          text: "商品已達購買上限，無法再進行購買。",
          icon: "warning",
          confirmButtonText: "關閉",
        });
      }
    },
  },
  computed: {
    couponType: function () {
      return this.$store.state.couponType;
    },
    maxItemQty: function () {
      // 如果有campaigns，則以最大campaign的qty為主
      let { item, couponType } = this;
      if (item.campaigns.length > 0) {
        // 排序campaigns，較大的為主
        let preferedCampaign = item.campaigns.slice().sort((a, b) => b.qty - a.qty)[0];
        // 判斷當前活動是否符合
        if (preferedCampaign.coupon_types.includes(couponType)) {
          // 需扣除相同活動已用過的數量
          let campaign_available_qty = preferedCampaign.qty - item.current_user_has_bought_qty;
          if (item.onhand_qty) {
            if (campaign_available_qty >= parseInt(item.onhand_qty) - item.total_has_bought_qty) {
              return parseInt(item.onhand_qty) - item.total_has_bought_qty;
            } else {
              return campaign_available_qty;
            }
          } else {
            return campaign_available_qty;
          }
        } else {
          if (item.onhand_qty) {
            return parseInt(item.onhand_qty) - item.total_has_bought_qty;
          } else {
            return 99;
          }
        }
      } else if (item.onhand_qty) {
        return parseInt(item.onhand_qty) - item.total_has_bought_qty;
      } else {
        // 暫定最大為99
        return 99;
      }
    },
    currentCart: function () {
      return this.$store.state.currentCart;
    },
  },
  watch: {
    modalOpen: function () {
      if (this.modalOpen) {
        $(`#flexSlider${this.item.code}`).flexslider({
          animation: "slide",
          controlNav: false,
          animationLoop: false,
          slideshow: false,
          sync: `#flexCarousel${this.item.code}`,
        });
        $(`#flexCarousel${this.item.code}`).flexslider({
          animation: "slide",
          controlNav: false,
          animationLoop: false,
          slideshow: false,
          itemWidth: 114,
          itemMargin: 20,
          asNavFor: `#flexSlider${this.item.code}`,
        });
      }
    },
    currentCart: function () {
      if (Object.keys(this.currentCart).includes(this.item.key)) {
        this.qty = this.currentCart[this.item.key] ? this.currentCart[this.item.key].qty : 1;
      } else {
        this.qty = 1;
      }
    },
  },
};
</script>
