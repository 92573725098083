<template>
  <li v-if="onlyItemsPage && requireCustomers && customers.length > 0">
    <label>
      客戶選擇:
      <select v-model="currentCustomerId" @change.prevent="changeCustomer" style="width: 150px">
        <option
          :key="`customer${index}`"
          v-for="(customer, index) in customers"
          :value="customer.key"
        >
          {{ customer.value }}
        </option>
      </select>
    </label>
  </li>
</template>

<script>
import Swal from "sweetalert2";
import axios from "axios";
export default {
  data() {
    return {
      currentCustomerId: null,
      tempCustomerId: null,
      customers: [],
      requireCustomers: false,
    };
  },
  computed: {
    getCustomers() {
      return this.$store.state.customers;
    },
    getCurrentCustomer() {
      return this.$store.state.currentCustomer;
    },
    onlyItemsPage: function () {
      // 允許出現的網址
      const allowedUrls = ["/items"];
      if (allowedUrls.includes(window.location.pathname)) {
        return true;
      } else {
        return false;
      }
    },
    couponType() {
      return this.$store.state.couponType;
    },
    couponTypes() {
      return this.$store.state.couponTypes;
    },
  },
  watch: {
    getCustomers: function () {
      this.customers = this.getCustomers;
    },
    getCurrentCustomer: function () {
      this.currentCustomerId = this.getCurrentCustomer ? this.getCurrentCustomer.id : null;
      this.tempCustomerId = this.getCurrentCustomer ? this.getCurrentCustomer.id : null;
      if (this.currentCustomerId) {
        // console.log("currentCouponType", this.couponTypes.find(type => this.couponType === type.key))
        this.requireCustomers = true;
      } else {
        this.requireCustomers = false;
      }
    },
    couponType: function () {
      const currentCouponType = this.couponTypes.find((type) => this.couponType === type.key);
      this.requireCustomers = currentCouponType ? currentCouponType.require_customers : false;
    },
  },
  methods: {
    changeCustomer: function (e) {
      Swal.fire({
        title: "確定要更改客戶嗎?",
        text: "更改後將清除購物車內容!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "確定",
        cancelButtonText: "取消",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.commit("set_items_loading", true);
          // 設定items為空值
          // this.$store.commit('set_items', []);
          this.$store.dispatch("setCurrentCustomer", e.target.value);
          // 清除購物車
          // 直接call api一次，再dispatch一次，避免session[:cart]一直無法清除的問題
          axios.delete("/api/clear_cart").then(({ data }) => {
            if (data.success) {
              this.$store.dispatch("clearCurrentCart");
              setTimeout(() => {
                this.$store.dispatch("clearCurrentCart");
              }, 500);
              setTimeout(() => {
                this.$store.dispatch("clearCurrentCart");
              }, 1000);
            }
          });
        } else {
          // this.$store.dispatch('setCurrentCustomer', this.tempCustomerId);
          this.currentCustomerId = this.tempCustomerId;
        }
      });
    },
  },
};
</script>
