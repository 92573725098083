<template>
  <div>
    <div v-if="itemShowMode === 'list'">
      <div v-if="renderItems.length > 0" class="row product">
        <single-item
          :key="`item_${index}`"
          v-for="(item, index) in renderItems"
          :item="item"
        ></single-item>
      </div>
    </div>
    <div v-if="itemShowMode === 'table'">
      <div class="table-responsive my-item-table">
        <table class="table table-hover table-sm-gap">
          <thead>
            <tr>
              <th></th>
              <th nowrap>圖示</th>
              <th nowrap>商品編號</th>
              <th nowrap>品名</th>
              <th nowrap>類別</th>
              <th nowrap>價格</th>
            </tr>
          </thead>
          <tbody>
            <tr
              is="item-row"
              :key="`item_${index}`"
              v-for="(item, index) in renderItems"
              :item="item"
            ></tr>
          </tbody>
        </table>
      </div>
      <!-- modal部分 -->
      <item-modal
        v-for="(item, index) in renderItems"
        :key="`item${index}`"
        :item="item"
        :qty="itemQty(item)"
        :maxItemQty="maxItemQty(item)"
        :couponType="couponType"
        :selectedAgreement="0"
      ></item-modal>
    </div>
    <!-- 查無資料 -->
    <div v-if="showNoResult" class="row product">
      <h3 class="d-inline-block">查無商品與庫存</h3>
    </div>
    <div v-if="itemsLoading" class="row product">
      <!-- 搜尋或找不到 -->
      <h3 class="d-inline-block">載入中</h3>
      <div class="d-inline-block">
        <svg
          class="svg-loading"
          version="1.1"
          id="L5"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 100 100"
          enable-background="new 0 0 0 0"
          xml:space="preserve"
        >
          <circle fill="#ffa6a8" stroke="none" cx="6" cy="50" r="6">
            <animateTransform
              attributeName="transform"
              dur="1s"
              type="translate"
              values="0 15 ; 0 -15; 0 15"
              repeatCount="indefinite"
              begin="0.1"
            />
          </circle>
          <circle fill="#ffa6a8" stroke="none" cx="30" cy="50" r="6">
            <animateTransform
              attributeName="transform"
              dur="1s"
              type="translate"
              values="0 10 ; 0 -10; 0 10"
              repeatCount="indefinite"
              begin="0.2"
            />
          </circle>
          <circle fill="#ffa6a8" stroke="none" cx="54" cy="50" r="6">
            <animateTransform
              attributeName="transform"
              dur="1s"
              type="translate"
              values="0 5 ; 0 -5; 0 5"
              repeatCount="indefinite"
              begin="0.3"
            />
          </circle>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tempItems: [],
      itemsPriceRange: [0, 0],
      itemsSortBy: "default",
      itemsSearch: "",
      showNoResult: false,
    };
  },
  methods: {
    maxItemQty: function (item) {
      if (item && item.campaigns.length > 0) {
        // 排序campaigns，較大的為主
        let preferedCampaign = item.campaigns.slice().sort((a, b) => b.qty - a.qty)[0];
        // 判斷當前活動是否符合
        if (preferedCampaign.coupon_types.includes(this.couponType)) {
          // 需扣除相同活動已用過的數量
          let campaign_available_qty = preferedCampaign.qty - item.current_user_has_bought_qty;
          if (item.onhand_qty) {
            if (campaign_available_qty >= parseInt(item.onhand_qty) - item.total_has_bought_qty) {
              return parseInt(item.onhand_qty) - item.total_has_bought_qty;
            } else {
              return campaign_available_qty;
            }
          } else {
            return campaign_available_qty;
          }
        } else {
          if (item.onhand_qty) {
            return parseInt(item.onhand_qty) - item.total_has_bought_qty;
          } else {
            return 99;
          }
        }
      } else if (item && item.onhand_qty) {
        return parseInt(item.onhand_qty) - item.total_has_bought_qty;
      } else {
        // 暫定最大為99
        return 99;
      }
    },
    itemQty: function (item) {
      if (item && Object.keys(this.currentCart).includes(item.key)) {
        return this.currentCart[item.key] ? this.currentCart[item.key].qty : 1;
      } else {
        return 1;
      }
    },
  },
  computed: {
    couponType: function () {
      return this.$store.state.couponType;
    },
    currentCart: function () {
      return this.$store.state.currentCart;
    },
    itemsLoading() {
      return this.$store.state.itemsLoading;
    },
    itemShowMode() {
      return this.$store.state.itemShowMode;
    },
    items() {
      return this.$store.state.items;
    },
    priceRange() {
      return this.$store.state.priceRange;
    },
    sortBy() {
      return this.$store.state.sortBy;
    },
    search() {
      return this.$store.state.search;
    },
    renderItems() {
      // 排除itemItems中是null的item
      let items = this.tempItems.filter((item) => item);
      let priceRange = this.itemsPriceRange;
      let sortBy = this.itemsSortBy;
      let search = this.itemsSearch;
      let renderItems = [];
      if (items.length > 0) {
        if (priceRange[0] === 0 && priceRange[1] === 0) {
          renderItems = items;
        } else {
          renderItems = items.filter((item) => {
            return (
              parseInt(item.range_amount) >= priceRange[0] &&
              parseInt(item.range_amount) <= priceRange[1]
            );
          });
        }
        if (sortBy === "asc") {
          renderItems.sort((a, b) => {
            return parseInt(a.range_amount) - parseInt(b.range_amount);
          });
        } else if (sortBy === "desc") {
          renderItems.sort((a, b) => {
            return parseInt(b.range_amount) - parseInt(a.range_amount);
          });
        }
        if (search !== "") {
          renderItems = renderItems.filter((item) => {
            if (item && item.title && item.code) {
              return (
                item.title.toLowerCase().includes(search.toLowerCase()) ||
                item.code.toLowerCase().includes(search.toLowerCase())
              );
            }
          });
        }
      }
      return renderItems;
    },
  },
  watch: {
    items: function () {
      this.tempItems = this.items.filter((item) => item);
    },
    priceRange: function () {
      this.itemsPriceRange = this.priceRange;
    },
    sortBy: function () {
      this.itemsSortBy = this.sortBy;
    },
    search: function () {
      this.itemsSearch = this.search;
    },
  },
  updated() {
    this.$store.commit("set_items_loading", false);
    if (this.renderItems.length === 0) {
      this.showNoResult = true;
    } else {
      this.showNoResult = false;
    }
  },
};
</script>
