import Vue from "vue/dist/vue.js";
import Vuex from "vuex";
import axios from "axios";

var csrf = $("meta[name=csrf-token]").attr("content");
axios.defaults.headers.common["X-CSRF-Token"] = csrf;

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    itemShowMode: "table",
    currentCart: {},
    couponTypes: [],
    couponType: null,
    customers: [],
    currentCustomer: null,
    items: [],
    priceRange: [0, 0],
    sortBy: "default",
    search: "",
    currentCoupons: [],
    itemsLoading: true,
    restoreOrder: {},
    // cartCustomAmount: {}
  },
  mutations: {
    set_coupon_types: (state, payload) => {
      state.couponTypes = payload;
    },
    set_coupon_type: (state, payload) => {
      state.couponType = payload;
    },
    set_customers: (state, payload) => {
      state.customers = payload;
    },
    set_current_customer: (state, payload) => {
      state.currentCustomer = payload;
    },
    set_items: (state, payload) => {
      state.items = payload;
    },
    set_price_range: (state, payload) => {
      state.priceRange = payload;
    },
    set_sort_by: (state, payload) => {
      state.sortBy = payload;
    },
    set_search: (state, payload) => {
      state.search = payload;
    },
    set_current_cart: (state, payload) => {
      state.currentCart = payload;
    },
    set_current_coupons: (state, payload) => {
      state.currentCoupons = payload;
    },
    set_item_show_mode: (state, payload) => {
      state.itemShowMode = payload;
    },
    set_items_loading: (state, payload) => {
      // console.log('set_items_loading', payload);
      state.itemsLoading = payload;
    },
    set_restore_order: (state, payload) => {
      state.restoreOrder = payload;
    },
  },
  actions: {
    getCouponTypes: (context) => {
      axios.get("/api/coupon_types").then(({ data }) => {
        context.commit("set_coupon_types", data);
      });
    },
    getCurrentCouponType: (context) => {
      axios.get("/api/get_current_coupon_type").then(({ data }) => {
        context.commit("set_coupon_type", data.current_coupon_type);
      });
    },
    getCurrentCustomer: (context) => {
      axios.get("/api/get_current_customer").then(({ data }) => {
        context.commit("set_current_customer", data.current_customer);
      });
    },
    getCustomers: (context) => {
      axios.get("/api/get_customers").then(({ data }) => {
        context.commit("set_customers", data.customers);
      });
    },
    setCurrentCustomer: (context, payload) => {
      axios.post("/api/set_current_customer", { id: payload }).then(({ data }) => {
        if (data.success) {
          context.commit("set_current_customer", data.customer);
        }
      });
    },
    getItems: (context, payload) => {
      const firstSubCategoryId = document
        .getElementById("app-meta")
        .getAttribute("data-first-sub-category-id");
      if (payload && payload.length > 0) {
        axios.get("/api/items", { params: { item_ids: payload } }).then(({ data }) => {
          if (data.success) {
            context.commit("set_items", data.items);
          }
        });
      } else {
        // console.log(firstSubCategoryId);
        axios
          .get("/api/items", { params: { sub_category_id: firstSubCategoryId } })
          .then(({ data }) => {
            if (data.success) {
              context.commit("set_items", data.items);
            }
          });
      }
    },
    getCurrentCart: (context) => {
      axios.get("/api/get_current_cart").then(({ data }) => {
        context.commit("set_current_cart", data.current_cart);
      });
    },
    getCurrentCoupons: (context) => {
      axios.get("/api/get_current_coupons").then(({ data }) => {
        // console.log('getCurrentCoupons', data)
        context.commit("set_current_coupons", data.coupons);
      });
    },
    clearCurrentCart: (context) => {
      // 需要慢一點所有用setTimeout 500的方式
      setTimeout(() => {
        axios
          .delete("/api/clear_cart")
          .then(({ data }) => {
            context.commit("set_current_cart", {});
          })
          .catch((error) => {
            console.log(error);
          });
      }, 500);
    },
    setCart: (context, payload) => {
      axios.post("/api/set_cart", payload).then(({ data }) => {
        context.commit("set_current_cart", data.current_cart);
      });
    },
    getRestoreOrder: (context) => {
      axios.get("/api/get_restore_order").then(({ data }) => {
        context.commit("set_restore_order", data.restore_order);
      });
    },
  },
  getters: {},
});

export default store;
