<template>
  <div v-if="Object.keys(currentCart).length > 0" class="mt-2 mb-5">
    <a href="/carts" class="submit-btn go-to-cart-link">查看購物車 & 結賬</a>
  </div>
</template>

<script>
export default {
  data() {
    return {
    }
  },
  computed: {
    currentCart() {
      return this.$store.state.currentCart
    },
  },
}
</script>