<template>
  <div id="item-meta">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: ["search-items"],
  computed: {
    couponType() {
      return this.$store.state.couponType;
    },
    couponTypes() {
      return this.$store.state.couponTypes;
    },
    currentCustomer() {
      return this.$store.state.currentCustomer;
    },
  },
  watch: {
    couponType: function () {
      // 如果有搜尋資料
      if (this.searchItems) {
        this.$store.dispatch("getItems", this.searchItems);
      } else {
        this.$store.dispatch("getItems");
      }
    },
    currentCustomer: function () {
      if (this.searchItems) {
        this.$store.dispatch("getItems", this.searchItems);
      } else {
        this.$store.dispatch("getItems");
      }
    },
  },
  created() {
    if (this.searchItems) {
      this.$store.dispatch("getItems", this.searchItems);
    } else {
      this.$store.dispatch("getItems");
    }
    this.$store.dispatch("getCurrentCart");
  },
};
</script>
