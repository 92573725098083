<template>
  <div>
    <slot></slot>
  </div>
</template>


<script>
// vue無法在本地端偵測customAmount的改變，所以要把customAmount放到store裡面，再從store中取出
import Swal from 'sweetalert2';
export default {

}
</script>