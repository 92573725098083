<template>
  <div>
    <vue-slider v-model="range" :min="min" :max="max"></vue-slider>
      <span class="float-left">價格區間: ${{range[0]}} - ${{range[1]}}</span>
  </div>
</template>

<script>
export default {
  data() {
    return {
      range: [0, 0],
      min: 0,
      max: 0,
    }
  },
  computed: {
    items() {
      return this.$store.state.items;
    }
  },
  watch: {
    items: function() {
      if (this.items.length > 0) {
        let min = this.items.slice().sort((a, b) => a.range_amount - b.range_amount)[0];
        let max = this.items.slice().sort((a, b) => b.range_amount - a.range_amount)[0];
        this.range = [parseInt(min.range_amount), parseInt(max.range_amount)];
        this.min = parseInt(min.range_amount);
        this.max = parseInt(max.range_amount);
      }
    },
    range: function() {
      this.$store.commit('set_price_range', this.range);
    }
  },
}
</script>