import Vue from "vue/dist/vue.js";
// 套件
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";
// store
import store from "../vue/store";
// components
import loginForm from "../vue/components/login_form.vue";
import couponTypeForm from "../vue/components/coupon_type_form.vue";
import RangeSlider from "../vue/components/range_slider.vue";
import ItemLists from "../vue/components/item_lists.vue";
import SingleItem from "../vue/components/single_item.vue";
import CouponTypesDropdown from "../vue/components/coupon_types_dropdown.vue";
import CustomersDropdown from "../vue/components/customers_dropdown.vue";
import ItemsMeta from "../vue/components/items_meta.vue";
import SortDropdown from "../vue/components/sort_dropdown.vue";
import SearchInput from "../vue/components/search_input.vue";
import MiniCart from "../vue/components/mini_cart.vue";
import CartItems from "../vue/components/cart_items.vue";
import GoToCart from "../vue/components/go_to_cart.vue";
import CartMeta from "../vue/components/cart_meta.vue";
import ShowMode from "../vue/components/show_mode.vue";
import ItemRow from "../vue/components/item_row.vue";
import ItemModal from "../vue/components/item_modal.vue";
import ExcelUpload from "../vue/components/excel_upload.vue";
import ExcelUploadSupport from "../vue/components/excel_upload_support.vue";

// vue init
document.addEventListener("DOMContentLoaded", () => {
  new Vue({
    el: "#vue-app",
    store,
    created() {
      // 取得類型
      this.$store.dispatch("getCouponTypes");
      this.$store.dispatch("getCurrentCouponType");
      this.$store.dispatch("getCurrentCustomer");
      this.$store.dispatch("getCustomers");
    },
  });
});

// 套件使用
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("VueSlider", VueSlider);
// components
Vue.component("login-form", loginForm);
Vue.component("coupon-type-form", couponTypeForm);
Vue.component("range-slider", RangeSlider);
Vue.component("item-lists", ItemLists);
Vue.component("single-item", SingleItem);
Vue.component("coupon-types-dropdown", CouponTypesDropdown);
Vue.component("customers-dropdown", CustomersDropdown);
Vue.component("items-meta", ItemsMeta);
Vue.component("sort-dropdown", SortDropdown);
Vue.component("search-input", SearchInput);
Vue.component("mini-cart", MiniCart);
Vue.component("cart-meta", CartMeta);
Vue.component("cart-items", CartItems);
Vue.component("go-to-cart", GoToCart);
Vue.component("show-mode", ShowMode);
Vue.component("item-row", ItemRow);
Vue.component("item-modal", ItemModal);
Vue.component("excel-upload", ExcelUpload);
Vue.component("excel-upload-support", ExcelUploadSupport);
