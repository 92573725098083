<template>
  <div class="search-blog">
    <input type="text" v-model="text" @keydown="keydown" placeholder="搜尋..." class="float-left">
    <button class="btn float-right" @click.prevent="changeText">
      <span class="lnr lnr-chevron-right"></span>
    </button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      text: ''
    }
  },
  methods: {
    changeText() {
      this.$store.commit('set_search', this.text);
    },
    keydown(e) {
      if (e.key === 'Enter') {
        this.$store.commit('set_search', this.text);
      }
    }
  }
}
</script>