<template>
  <div
    v-if="item"
    class="modal"
    :id="`itemModal${item.code}`"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content container">
        <div class="product-single-quick-view">
          <div class="row product_detail">
            <div class="col-md-6 col-sm-12 col-12">
              <div class="flex-quick-view">
                <div :id="`flexSlider${item.code}`" class="flexslider">
                  <ul v-if="item.images.length > 0" class="slides">
                    <li :key="`item_image_${index}`" v-for="(image, index) in item.images">
                      <img :src="image.image" :alt="image.title" />
                    </li>
                  </ul>
                  <ul v-if="item.images.length === 0" class="slides">
                    <li>
                      <img :src="item.image" :alt="item.title" />
                    </li>
                  </ul>
                </div>
                <div :id="`flexCarousel${item.code}`" class="flexslider">
                  <ul class="slides">
                    <li :key="`carousel_item_image_${index}`" v-for="(image, index) in item.images">
                      <img :src="image.image" :alt="image.title" />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-sm-12 col-12 content-product">
              <h2>{{ item.title }}</h2>
              <span v-html="item.content"></span>
              <div class="infor-product">
                <p><span>主類別: </span>{{ item.category_title }}</p>
                <p><span>子類別: </span>{{ item.sub_category_title }}</p>
                <p v-if="item.price_list_id && item.price_list_id.length > 0">
                  <span>價格表: </span>
                  <select v-model="selectedAgreement">
                    <option
                      :key="`priceListName${index}`"
                      v-for="(name, index) in item.price_list_name"
                      :value="index"
                    >
                      {{ name }}
                    </option>
                  </select>
                </p>
                <!--活動 campaign-->
                <div :key="`campaign${index}`" v-for="(campaign, index) in item.campaigns">
                  <p v-if="campaign.coupon_types.includes(couponType)">
                    <span>符合活動{{ index + 1 }}: </span>
                    {{ campaign.title }} | 活動可購: {{ campaign.qty }} | 已購:
                    {{ item.current_user_has_bought_qty }}
                    <!-- 最大值打勾 -->
                    <span v-if="index === 0 && item.campaigns.length > 0"
                      ><i class="fas fa-check" style="color: green; padding: 0"></i
                    ></span>
                  </p>
                </div>
                <p v-if="item.price"><span>牌價: </span>${{ item.price }}</p>
                <p>
                  <span>售價: </span>${{
                    item.amount_list ? item.amount_list[selectedAgreement] : item.amount
                  }}
                </p>
                <p v-if="!item.amount"><span>價格: </span>${{ item.unit_price }}</p>
                <p>
                  <span>產品說明書: </span>
                  <a target="_blank" :href="`http://idu.jourdeness.com/${item.code}`">{{
                    `http://idu.jourdeness.com/${item.code}`
                  }}</a>
                </p>
              </div>
              <div v-if="qty <= maxItemQty">
                <div class="btn-group">
                  <button type="button" class="prev btn" @click.prevent="minus">-</button>
                  <button type="button" class="show-number btn">{{ qty }}</button>
                  <button type="button" class="next btn" @click.prevent="plus">+</button>
                </div>
                <div class="btn-group">
                  <a @click.prevent="addToCart" href="#" class="btn add-to-cart">
                    加入購物車
                    <p><i class="fas fa-cart-plus"></i></p>
                  </a>
                </div>
              </div>
              <!-- 提醒無法購買 -->
              <p v-else>
                <span class="warning-msg">已超過活動或庫存可購上限!</span>
              </p>
            </div>
          </div>
          <span class="lnr lnr-cross close-quick-view" data-dismiss="modal"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  props: ["item", "qty", "max-item-qty", "coupon-type", "selected-agreement"],
  methods: {
    plus() {
      if (this.qty < this.maxItemQty) {
        this.qty++;
      }
    },
    minus() {
      if (this.qty > 1) {
        this.qty--;
      }
    },
    addToCart() {
      const url = "/api/add_to";
      const { item, maxItemQty, selectedAgreement } = this;
      // amount的判斷為，如果item中有agreement_id, price_list_id, price_list_name, amount_list(四個會一起出現)，則amount為amount_list[selectedAgreement]
      // 判斷數量是否符合活動
      if (this.qty > maxItemQty) {
        Swal.fire({
          title: "數量錯誤！",
          text: "可能超過可購上限",
          type: "warning",
          confirmButtonText: "確定",
        });
        return;
      }
      axios
        .post(url, {
          item_id: item.key,
          qty: this.qty,
          amount: item.agreement_id ? item.amount_list[selectedAgreement] : item.amount,
          max_qty: maxItemQty, // 可購上限，無論是否有符合活動(campaign)與api庫存檢查，會帶到購物車，所以購物車數量檢查用max_qty即可
          agreement_id: item.agreement_id?.length > 0 ? item.agreement_id[selectedAgreement] : null,
          campaign_id: item.campaigns[0]?.id, //可用item.campaigns[0]因為在server端已經做好排序(多到少)
          price_list_id:
            item.price_list_id?.length > 0 ? item.price_list_id[selectedAgreement] : null,
          price_list_name:
            item.price_list_name?.length > 0 ? item.price_list_name[selectedAgreement] : null,
        })
        .then(({ data }) => {
          if (data.success) {
            // 取得購物車
            this.$store.dispatch("getCurrentCart");
          }
          Swal.fire({
            title: data.title,
            text: data.message,
            icon: data.success ? "success" : "error",
            confirmButtonText: "關閉",
          });
        });
    },
  },
};
</script>
