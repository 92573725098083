<template>
  <li v-if="onlyItemsPage">
    <label>
      訂單類型:
      <select v-model="selectedCouponType" @change.prevent="selectCouponType">
        <option :key="`couponType${index}`" v-for="(type, index) in couponTypes" :value="type.key">
          {{ type.value }}
        </option>
      </select>
    </label>
  </li>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      selectedCouponType: null,
      tempCouponType: null,
    };
  },
  computed: {
    couponType() {
      return this.$store.state.couponType;
    },
    couponTypes() {
      return this.$store.state.couponTypes;
    },
    onlyItemsPage: function () {
      // 允許出現的網址
      const allowedUrls = ["/items"];
      if (allowedUrls.includes(window.location.pathname)) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    couponType: function () {
      this.selectedCouponType = this.couponType;
      this.tempCouponType = this.couponType;
    },
  },
  methods: {
    selectCouponType: function (e) {
      Swal.fire({
        title: "確定要更改訂單類型嗎?",
        text: "更改後將清除購物車內容!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "確定",
        cancelButtonText: "取消",
      }).then((result) => {
        if (result.isConfirmed) {
          this.selectedCouponType = e.target.value;
          this.tempCouponType = e.target.value;
          this.$store.commit("set_items_loading", true);
          // 設定items為空值
          // this.$store.commit('set_items', []);
          // console.log('e.target.value', e.target.value)
          const url = "/api/set_coupon_type";
          axios
            .post(url, {
              coupon_type: this.selectedCouponType,
            })
            .then(({ data }) => {
              if (data.success) {
                // 設定store
                this.$store.commit("set_coupon_type", this.selectedCouponType);
                // 如果有選擇客戶
                if (data.show_customers_select_box) {
                  this.customers = data.customers;
                  // 設定store的customers
                  if (this.customers.length > 0) {
                    this.$store.commit("set_customers", data.customers);
                    this.$store.dispatch("setCurrentCustomer", data.customers[0].key);
                  }
                } else {
                  // 設定store的customers
                  this.$store.commit("set_customers", []);
                  this.$store.dispatch("setCurrentCustomer", null);
                }
                // 清除購物車
                // 直接call api一次，再dispatch一次，避免session[:cart]一直無法清除的問題
                axios.delete("/api/clear_cart").then(({ data }) => {
                  console.log(data);
                  if (data.success) {
                    this.$store.dispatch("clearCurrentCart");
                    setTimeout(() => {
                      this.$store.dispatch("clearCurrentCart");
                    }, 500);
                    setTimeout(() => {
                      this.$store.dispatch("clearCurrentCart");
                    }, 1000);
                    setTimeout(() => {
                      window.location.reload();
                    }, 1200);
                  }
                });
              } else {
                Swal.fire({
                  title: "錯誤",
                  text: data.message,
                  icon: "error",
                  confirmButtonText: "關閉",
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          this.selectedCouponType = this.tempCouponType;
        }
      });
    },
  },
};
</script>
