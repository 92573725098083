<template>
  <div>
    <!-- 登入表單 -->
    <div v-if="currentForm === 'login'">
      <ValidationObserver class="posr" v-slot="{handleSubmit}">
        <form @submit.prevent="handleSubmit(login)">
          <p>請輸入帳號 <span>*</span></p>
          <ValidationProvider tag="div" class="validation posr" rules="required" v-slot="{errors}">
            <input type="text" v-model="employee_id" placeholder="請輸入帳號" class="text">
            <div class="form-error">{{ errors[0] }}</div>
          </ValidationProvider>
          <p>密碼<span>*</span></p>
          <ValidationProvider tag="div" class="validation posr" rules="required" v-slot="{errors}">
            <input type="password" v-model="password" placeholder="請輸入密碼" class="text">
            <div class="form-error">{{ errors[0] }}</div>
          </ValidationProvider>
          <button type="submit" class="bt">登入</button>
          <div style="margin-top: 20px;">
            <a href="/forget-password" class="text-left lost-password">忘記密碼</a>
          </div>
        </form>
      </ValidationObserver>
    </div>
    <!-- 選擇類別與客戶 -->
    <coupon-type-form v-if="currentForm === 'select_coupon_type'" :default_selected_coupon_type="default_selected_coupon_type"></coupon-type-form>
  </div>
</template>

<script>
import { extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import axios from 'axios';
import coupon_type_form from './coupon_type_form.vue';
import Swal from 'sweetalert2';

var csrf = $("meta[name=csrf-token]").attr("content");
axios.defaults.headers.common['X-CSRF-Token'] = csrf;

extend('required', {
  ...required,
  message: '必填欄位'
});

export default {
  components: {
    coupon_type_form
  },
  props: ['default_selected_coupon_type'],
  data() {
    return {
      employee_id: '',
      password: '',
      currentForm: 'login',
    }
  },
  methods: {
    login() {
      const url = '/api/login';
      axios.post(url, {
        employee_id: this.employee_id,
        password: this.password
      }).then(({data}) => {
        if (data.status === 'success') {
          // 更新類型
          this.$store.dispatch('getCouponTypes');
          // 選擇類別
          this.currentForm = 'select_coupon_type';
        } else {
          Swal.fire({
            title: '錯誤',
            text: data.message,
            icon: 'error',
            confirmButtonText: '關閉'
          })
        }
      }).catch(error => {
        console.log(error);
      })
    },
  }
}
</script>