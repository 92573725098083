<template>
  <select v-model="sortBy" @change.prevent="changeSort" class="float-right">
    <option value="default">預設</option>
    <option value="asc">價格低至高</option>
    <option value="desc">價格高至低</option>
  </select>
</template>

<script>
export default {
  data() {
    return {
      sortBy: 'default'
    }
  },
  methods: {
    changeSort() {
      if (this.sortBy === 'asc') {
        this.$store.commit('set_sort_by', 'asc');
      } else if (this.sortBy === 'desc') {
        this.$store.commit('set_sort_by', 'desc');
      } else {
        this.$store.commit('set_sort_by', 'default');
      }
    }
  }
}
</script>