<template>
  <li v-if="couponType === 'benefit'">
    <a href="/upload_example.xlsx" download="upload_example.xlsx" style="color:#29c48e;">範例下載</a>
    <form action="/items/file_upload" method="post" enctype="multipart/form-data" style="display:inline">
      <input type="hidden" name="authenticity_token" :value="csrf">
      <input type="hidden" name="sub_category_id" :value="subCategoryId">
      <input id="fileupload" name="file" type="file" style="display:none;" @change.prevent="getFilename" />
      <button type="button" onclick="document.getElementById('fileupload').click();" class="fileupload">{{filename || '選擇檔案'}}</button>
      <button type="submit" class="upload_btn">上傳</button>
    </form>
  </li>
</template>

<script>
export default {
  data() {
    return {
      filename: null,
      subCategoryId: null
    }
  },
  methods: {
    getFilename(e) {
      const file = e.target.files[0]
      if (file) {
        this.filename = file.name
      }
    },
    getSubCategoryIdFromUrl() {
      const queryParams = new URLSearchParams(window.location.search);
      this.subCategoryId = queryParams.get('sub_category_id');
    },
  },
  computed: {
    couponType() {
      return this.$store.state.couponType;
    },
    csrf() {
      return $("meta[name=csrf-token]").attr("content");
    },
  },
  mounted() {
    this.getSubCategoryIdFromUrl();
  }
}
</script>