<template>
  <div class="col-md-6 col-sm-4 col-4">
    <p>
      <span
        @click.prevent="setMode('table')"
        :class="`lnr lnr-list lnr-icon-common ${itemShowMode === 'table' ? 'lnr-current' : ''}`"
      ></span>
      <span
        @click.prevent="setMode('list')"
        :class="`lnr lnr-picture lnr-icon-common ${itemShowMode === 'list' ? 'lnr-current' : ''}`"
      ></span>
    </p>
  </div>
</template>

<script>
export default {
  computed: {
    itemShowMode() {
      return this.$store.state.itemShowMode;
    },
  },
  methods: {
    setMode(mode) {
      this.$store.commit("set_item_show_mode", mode);
    },
  },
};
</script>
