<template>
  <div class="content-checkout">
    <div class="billing-detail">
      <p>請選擇訂單類型 <span>*</span></p>
      <select v-model="selectedCouponType" @change="changeCouponType">
        <option :key="`couponType${index}`" v-for="(couponType, index) in couponTypes" :value="couponType.key">{{ couponType.value }}</option>
      </select>
      <div v-if="showCustomerSelect">
        <p>請選擇客戶 <span>*</span></p>
        <select v-model="selectedCustomer" @change="changeCustomer">
          <option :key="`customer_${index}`" v-for="(customer, index) in customers" :value="customer.key">{{customer.value}}</option>
        </select>
      </div>
      <button type="button" class="stdBtn" @click.prevent="goItems">開始選購</button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';

export default {
  props: ['default_selected_coupon_type'],
  data() {
    return {
      showCustomerSelect: (this.default_selected_coupon_type === 'purchasing' || this.default_selected_coupon_type === 'trade') || false,
      selectedCouponType: this.default_selected_coupon_type,
      selectedCustomer: null,
      customers: [],
      couponTypes: []
    }
  },
  computed: {
    userCustomers() {
      return this.$store.state.customers;
    },
    currentCustomer() {
      return this.$store.state.currentCustomer;
    }
  },
  watch: {
    userCustomers: function() {
      this.customers = this.userCustomers;
    },
    selectedCouponType: function() {
      if (this.selectedCouponType === 'purchasing' || this.selectedCouponType === 'trade') {
        this.showCustomerSelect = true;
      } else {
        this.showCustomerSelect = false;
      }
    },
    currentCustomer: function() {
      this.selectedCustomer = this.currentCustomer.id;
    }
  },
  methods: {
    changeCouponType() {
      const {selectedCouponType} = this;
      // 設定server與store的coupon_type
      const url = '/api/set_coupon_type';
      axios.post(url, {
        coupon_type: selectedCouponType
      }).then(({data}) => {
        // console.log(data)
        if (data.success) {
          // 設定store
          this.$store.commit('set_coupon_type', selectedCouponType);
          // 如果有選擇客戶
          if (data.show_customers_select_box) {
            this.showCustomerSelect = true;
            this.customers = data.customers;
            // 設定store的customers
            if (this.customers.length > 0) {
              // e.g. {key: customer的id, value: customer的name}
              this.selectedCustomer = this.customers[0].key;
              this.$store.commit('set_customers', this.customers);
              this.$store.dispatch('setCurrentCustomer', this.selectedCustomer);
            }
          } else {
            this.showCustomerSelect = false;
            this.selectedCustomer = null;
            // 設定store的customers
            this.$store.commit('set_customers', []);
            this.$store.dispatch('setCurrentCustomer', null);
          }
        } else {
          Swal.fire({
            title: '錯誤',
            text: data.message,
            icon: 'error',
            confirmButtonText: '關閉'
          })
        }
      }).catch(error => {
        console.log(error);
      });
    },
    changeCustomer() {
      // console.log(this.selectedCustomer);
      this.$store.dispatch('setCurrentCustomer', this.selectedCustomer);
    },
    goItems() {
      // 判斷coupon_type是否需要customer
      // window.location.href = '/items';
      // 如果coupon_type需要customer
      if (this.showCustomerSelect && !this.selectedCustomer) {
        Swal.fire({
          title: '錯誤',
          text: '請選擇客戶',
          icon: 'error',
          confirmButtonText: '關閉'
        })
      } else {
        window.location.href = '/items';
      }
    }
  },
  created() {
    // 更新coupon_types
    axios.get('/api/coupon_types').then(({data}) => {
      this.couponTypes = data;
    }).catch(error => {
      console.log(error);
    });
  }
}
</script>